@media print, screen and (max-width: 61.25em) {

	.logo {
		text-align: center;
		margin-bottom: 20px;
	}

	.title-bar {
		display: block;
	}

	#main-menu {
		display: none;
	}

	.dropdown.menu a {
		text-align: center;
	}

	.img-anfahrt {
		position: static;
		width: 100%;
	}
}

@include breakpoint(small only) {

	.title-bar {
		right: 0;
		top: 0;
	}

	.column.produkt {
		p {
			font-size: rem-calc(13px);
		}
	}

	.footer {
		text-align: center;

		.footer-col-1,
		.footer-col-2 {
			text-align: center;

			p > img:first-child {
				padding-left: 0;
			}
		}

		.menu {
			display: block;
			margin-right: auto;
			margin-left: auto;
			float: none !important;
		}
	}
}
