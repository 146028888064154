
.header {
	background-color: #ebe9e9;
	padding: 80px 0 0;

	.menu {
		li.current a {
			border-bottom: 2px solid $primary-color;
		}

		a {
			font-size: rem-calc(15px);
			font-weight: 700;
			text-transform: uppercase;
			border-bottom: 2px solid transparent;

			&:hover {
				border-bottom: 2px solid $primary-color;
			}
		}
	}
}

.header-image {
	margin-top: 20px;

	img {
		width: 100%;
	}
}

.title-bar {
	position: absolute;
	right: 20px;
	top: 85px;
	display: none;
}

.header .row {
	position: relative;
}

.lang-switch {
	position: absolute;
	right: 1rem;
	top: -70px;

	a:hover,
	a.active {
		border-bottom: 2px solid $primary-color;
	}
}
