
.footer {
	background-color: #535353;
	color: $white;
	font-weight: 300;
	padding: 60px 0;

	a {
		color: $white;
		font-weight: 300;
	}

	.footer-col-2 {
		img {
			padding-left: 50px;
		}
	}

	.menu {
		margin-top: 60px;
		padding-top: 15px;
		border-top: 2px solid $white;

		a {
			text-transform: uppercase;
		}
	}
}