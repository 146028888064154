.main {
	.map,
	.row {
		padding: 15px 0;
	}

	a {
		color: $primary-color;
	}

	blockquote {
		border-top: 1px dashed $primary-color;
		border-bottom: 1px dashed $primary-color;
		border-left: 0 none;
		border-right: 0 none;
		font-style: italic;
		color: #656b6f;
	}

	hr {
		border-bottom: 1px dashed $primary-color;
		width: 25%;
		margin: 1.25rem 0;
		display: block;
	}

	.big {
		font-size: rem-calc(24px);
		text-transform: uppercase;

		&.orange {
			color: $primary-color;
		}
	}

	.btn {
		background: transparent url(../img/btn.png) no-repeat 0 0;
		line-height: 45px;
		width: 162px;
		text-align: center;
		color: $white;
		display: block;
	}

	.download {
		background: transparent url(../img/icon-download.png) no-repeat left center;
		color: $black;
		line-height: 60px;
		padding-left: 60px;
		margin-bottom: 10px;
		display: block;
	}

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: .5s ease;
		background-color: rgba(#ffffff, 0.7);
		display: block;
	}

	.column.produkt  {
		margin-bottom: 30px;
		color: #000000;
		font-weight: 400;

		a {
			position: relative;
			display: inline-block;

			&:hover .overlay {
				opacity: 1;
			}
		}
	}

	.text {
		color: $black;
		font-size: rem-calc(40px);
		font-weight: 700;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}
}

.img-anfahrt {
	position: absolute;
	width: 53%;
	left: 0;
}