/** Wrapper Styles **/

h1, h3 {
	text-transform: uppercase;
	font-weight: 300;
}

.wrapper {
	overflow: hidden;
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
	float:left;
}

.row {
	clear: both;
}